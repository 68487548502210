import {
  OPTION_BUSINESS_TYPE,
  JOB_OPTION_TYPE,
  COMMERCE_OPTION_ITEMS_DESCRIPTION,
} from "@/shared/interface/newItem";
import { WORKITEM_PARAM_DEFINITION } from "@/shared/interface/workItem";

export const InfoUtils = {
  isIdenticalParams(
    currentParamOptions: WORKITEM_PARAM_DEFINITION[],
    runbookParamOptions: any[]
  ): boolean {
    currentParamOptions = currentParamOptions.filter((option) => {
      if (option.hidden !== "true") return option;
    });
    runbookParamOptions = runbookParamOptions.filter((option) => {
      if (option?.hidden === "false" || option?.hidden === undefined)
        return option;
    });
    //check length
    if (currentParamOptions.length !== runbookParamOptions.length) return false;
    runbookParamOptions =
      this.setOptionTypeforRunbookParams(runbookParamOptions);

    const isIdentical = runbookParamOptions.every((runbookOption, index) => {
      let isIdenticalParamOptions = false;
      const isIdenticalName =
        runbookOption._name === currentParamOptions[index]._name;
      const isIdenticalType =
        runbookOption.type === currentParamOptions[index].type;
      const isIdenticalLabel =
        runbookOption.label === currentParamOptions[index].label;
      const isIdenticalDescription =
        runbookOption?.description === currentParamOptions[index]?.description;
      const isIdenticalRegex =
        runbookOption?._regex === currentParamOptions[index]?.regex;
      const isIdenticalAllowedValues =
        runbookOption?._values === currentParamOptions[index]?.allowedValues;
      const isIdenticalDefaultResourceType =
        runbookOption?._defaultResourceType ===
        currentParamOptions[index]?.defaultResourceType;
      const isIdenticalAllowedResourceType =
        runbookOption?._allowedResourceTypes ===
        currentParamOptions[index]?.allowedResourceTypes;
      const isIdenticalAllowedResourceNum =
        runbookOption?._allowedResourceNum ===
        currentParamOptions[index]?.allowedResourceNum;
      let isIdenticalDate = true;
      if (runbookOption.isDate) {
        const hasDateRange = runbookOption.dateFormat.includes("(range)");
        let runbookDateFormat = runbookOption.dateFormat;
        runbookDateFormat = hasDateRange
          ? runbookDateFormat.substring(0, runbookDateFormat.length - 7)
          : runbookDateFormat;
        isIdenticalDate =
          currentParamOptions[index]?.dateFormat === runbookDateFormat;
      }
      const isIdenticalParamArr = [
        isIdenticalName,
        isIdenticalType,
        isIdenticalLabel,
        isIdenticalDate,
        isIdenticalDescription,
        isIdenticalRegex,
        isIdenticalAllowedValues,
        isIdenticalDefaultResourceType,
        isIdenticalAllowedResourceType,
        isIdenticalAllowedResourceNum,
      ];
      isIdenticalParamOptions = isIdenticalParamArr.every(
        (val) => val === true
      );
      if (!isIdenticalParamOptions)
        console.warn("Different param: " + runbookOption._name);
      return isIdenticalParamOptions;
    });
    return isIdentical;
  },

  setOptionTypeforRunbookParams(runbookparamOptions): any {
    runbookparamOptions.forEach((item) => {
      if (
        !!item.description &&
        item.description.includes(OPTION_BUSINESS_TYPE.Commerce)
      ) {
        if (
          item.description.includes("DTU") ||
          item.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.ccv2DBs
        ) {
          item.type = JOB_OPTION_TYPE.cascader;
          return;
        }
        switch (item.description) {
          case COMMERCE_OPTION_ITEMS_DESCRIPTION.futureDate:
            item.type = JOB_OPTION_TYPE.date;
            break;
          case COMMERCE_OPTION_ITEMS_DESCRIPTION.subscriptions:
            item.type = JOB_OPTION_TYPE.select;
            break;
          case COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResources:
          case COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResourcesNames:
            item.type = JOB_OPTION_TYPE.multiselect;
            break;
          case COMMERCE_OPTION_ITEMS_DESCRIPTION.datewithTz:
          case COMMERCE_OPTION_ITEMS_DESCRIPTION.dateRangewithTz:
            item.type = JOB_OPTION_TYPE.date;
            item.timeZone = "";
            break;
          default:
            item.type = JOB_OPTION_TYPE.commerce;
            break;
        }
      } else if (item._secure) {
        item.type = JOB_OPTION_TYPE.secure;
      } else if (!!item._values || !!item._valuesUrl) {
        item.type = JOB_OPTION_TYPE.select;
      } else if (item._type === "file") {
        item.type = JOB_OPTION_TYPE.file;
      } else {
        if (item._multivalued === "true") {
          item.type = JOB_OPTION_TYPE.tag;
        } else if (item.isDate === "true") {
          item.type = JOB_OPTION_TYPE.date;
        } else {
          item.type = JOB_OPTION_TYPE.input;
        }
      }
    });
    return runbookparamOptions;
  },
};

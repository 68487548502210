
import "./Log.scss";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import cloneDeep from "lodash.clonedeep";
import groupBy from "lodash.groupby";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { EXECUTION_STATUS } from "@/shared/interface/workItemDetails";

@Component({
  name: "LogComponent",
  components: { DynamicScroller, DynamicScrollerItem },
  filters: {
    parseJson: function (value: string) {
      if (!value) return "";
      try {
        value = JSON.stringify(JSON.parse(value), null, 3);
      } catch (e) {
        return value;
      }
      return value;
    },
  },
})
export default class LogComponent extends Vue {
  @Prop({ default: false }) list;
  @Prop({ default: false }) noLogs;
  @Prop({ default: EXECUTION_STATUS.initialization }) status;

  logList: any = [];
  activeName = 0;
  logVal = false;

  currentStatus = "";

  jobStatus: any = {
    SUCCESS: {
      class: "success",
      icon: "el-icon-check",
    },
    FAILED: {
      class: "failed",
      icon: "el-icon-close",
    },
    RUNNING: {
      class: "running",
      icon: "el-icon-more-outline",
    },
  };

  @Watch("noLogs", { deep: true, immediate: true })
  setNoLogs(val: boolean): void {
    this.logVal = val;
  }

  @Watch("list", { deep: true, immediate: true })
  setLogs(val: string): void {
    let sourceData = val ? cloneDeep(val) : "";
    if (sourceData) {
      if (
        ["succeeded", "aborted"].includes(sourceData.execState) ||
        this.status === "SUCCEEDED"
      ) {
        this.currentStatus = "SUCCESS";
      } else if (
        ["failed", "timedout"].includes(sourceData.execState) ||
        ["FAILED", "NA"].includes(this.status)
      ) {
        this.currentStatus = "FAILED";
      } else {
        this.currentStatus = "RUNNING";
      }
    } else {
      this.logList = [];
      return;
    }
    let newData: any = [];
    if (sourceData) {
      if (sourceData.offset === "0" && sourceData.message === "Pending") {
        newData.push({
          node: "localhost",
          logs: [
            {
              time: "Running",
              log: "Waiting for output to start...",
            },
          ],
        });
      } else {
        const entries = [...sourceData.entries];
        let groupData = groupBy(entries, "node");

        for (let key in groupData) {
          newData.push({
            node: key,
            logs: groupData[key],
          });
        }
      }
      this.logList = newData;
    }
  }

  downloadLogs(): void {
    let logText = "";
    this.logList[0].logs.forEach((logItem) => {
      const logMsg = JSON.stringify(logItem.log, null, 2);
      logText += `${logItem.time}:  ${logMsg} \n`;
    });

    const file = new Blob([logText], { type: "text/plain" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = this.$route.query.id + ".txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.tableData[0] &&
    [
      _vm.EXECUTION_STATUS.succeed,
      _vm.EXECUTION_STATUS.failed,
      _vm.EXECUTION_STATUS.submitFailed,
      _vm.EXECUTION_STATUS.aborted,
      _vm.EXECUTION_STATUS.timedout,
    ].includes(_vm.tableData[0].status)
  )?_c('div',{staticClass:"summary_model"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},_vm._l((_vm.tableTitle),function(item,index){return _c('el-table-column',{key:index,attrs:{"prop":item.prop,"label":item.label,"min-width":item.width,"fixed":item.fixed},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.prop === 'filePath')?_c('el-button',{attrs:{"type":"primary","size":"mini","plain":"","loading":_vm.downloadingReport},on:{"click":_vm.handleDownloadClick}},[_vm._v(" Download ")]):(
            item.prop === 'scheduleType' && _vm.jobType === _vm.DEFAULT_JOB_TYPE
          )?_c('p',[_vm._v(" "+_vm._s(_vm.DEFAULT_JOB_TYPE)+" ")]):_c('span',[_vm._v(_vm._s(scope.row[item.prop]))])]}}],null,true)},[(['startTime', 'endTime'].includes(item.prop))?_c('template',{slot:"header"},[_c('span',[_vm._v(_vm._s(item.label))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":`Timezone: ${_vm.timezoneSettings.currentTimezone} (UTC+${_vm.timezoneSettings.currentTimezoneOffset})`,"placement":"top-start"}},[_c('i',{staticClass:"el-icon-question"})])],1):_vm._e()],2)}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
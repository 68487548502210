var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-timeline',{staticClass:"info_model"},[_vm._l((_vm.steps),function(step,index){return [_c('el-timeline-item',{key:index,attrs:{"icon":step.icon,"color":step.color}},[_c('div',{staticClass:"step_content"},[(step.name !== 'Submit')?_c('div',{staticClass:"step_content_name"},[_c('span',[_vm._v(_vm._s(step.name))]),(step.name === 'Parameters' && _vm.showCopyParam)?_c('span',{staticClass:"copyworkitem",on:{"click":function($event){return _vm.goToNewWorkItem(_vm.caseInfo.parameters)}}},[_vm._v("Copy to a new work item")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"step_content_form"},[(step.name === 'Product')?_c('div',{staticClass:"form_card"},[_c('el-radio',{attrs:{"label":_vm.caseInfo.product,"border":""},model:{value:(_vm.caseInfo.product),callback:function ($$v) {_vm.$set(_vm.caseInfo, "product", $$v)},expression:"caseInfo.product"}},[_vm._v(_vm._s(_vm.getProduct()))])],1):_vm._e(),(step.name === 'Executor')?_c('div',{staticClass:"form_card"},[_c('el-radio',{attrs:{"label":_vm.caseInfo.executor,"border":""},model:{value:(_vm.caseInfo.executor),callback:function ($$v) {_vm.$set(_vm.caseInfo, "executor", $$v)},expression:"caseInfo.executor"}},[_vm._v(_vm._s(_vm.executorName))])],1):_vm._e(),(step.name === 'Runbook')?_c('div',{staticClass:"form_select h40"},[(_vm.caseInfo.runbook)?_c('el-button',{staticStyle:{"text-align":"left"},attrs:{"plain":""}},[_vm._v(_vm._s(_vm.caseInfo.runbook))]):_vm._e()],1):_vm._e(),(step.name === 'Parameters')?_c('div',[(
                Array.isArray(_vm.caseInfo.parameters) &&
                _vm.caseInfo.parameters.length > 0
              )?_c('div',{staticClass:"parameters"},[(!_vm.isAllHide)?[_vm._l((_vm.caseInfo.parameters),function(item,index){return [(
                      (item.hasOwnProperty('hidden') &&
                        item.hidden === 'false') ||
                      !item.hasOwnProperty('hidden')
                    )?_c('div',{key:index,staticClass:"flex-box item"},[(
                        item.type === 'multiselect' &&
                        typeof item.value !== 'string'
                      )?_c('div',{staticClass:"multiselectContainer"},[_c('div',[_c('label',{staticStyle:{"width":"'100% !important'"}},[_vm._v(_vm._s(item.label)+" ")]),_vm._l((item.value),function(resource,index){return _c('div',{key:resource.customerCode,staticClass:"multiselectparam"},[(typeof resource === 'string')?[_c('label',[_vm._v(_vm._s(index + 1)+" ")]),_c('div',[_c('label',[_vm._v("Customer Name ")]),_c('label',[_vm._v("Customer Code")]),_c('label',[_vm._v("Resource Name")])]),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.getParsedJSONValue(resource, "customerName"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getParsedJSONValue(resource, "customerCode"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getParsedJSONValue(resource, "resourceName"))+" ")])])]:[_c('label',[_vm._v(_vm._s(index + 1)+" ")]),_c('div',[_c('label',[_vm._v("Customer Name ")]),_c('label',[_vm._v("Customer Code")]),_c('label',[_vm._v("Resource Name")])]),_c('div',[_c('div',[_vm._v(_vm._s(resource.customerName))]),_c('div',[_vm._v(_vm._s(resource.customerCode))]),_c('div',[_vm._v(_vm._s(resource.resourceName))])])]],2)})],2)]):[_c('label',[_vm._v(_vm._s(item.label)+" ")]),(
                          !item.hasOwnProperty('_secure') ||
                          (item.hasOwnProperty('_secure') &&
                            item._secure === 'false')
                        )?_c('div',{staticClass:"content",staticStyle:{"width":"100%","height":"100%","overflow-y":"auto","word-wrap":"break-word"},domProps:{"innerHTML":_vm._s(_vm.filterData(item))}}):_c('div',{staticClass:"password content"},[_c('el-input',{attrs:{"type":"password"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)]],2):_vm._e()]})]:[_c('span',{staticStyle:{"color":"#999"}},[_vm._v(_vm._s(_vm.noShowParameterNotice))])]],2):(
                !Array.isArray(_vm.caseInfo.parameters) &&
                JSON.stringify(_vm.caseInfo.parameters) !== '{}'
              )?_c('div',{staticClass:"parameters"},_vm._l((_vm.caseInfo.parameters),function(item,index){return _c('div',{key:index,staticClass:"flex-box item"},[_c('label',[_vm._v(_vm._s(index)+" ")]),_c('div',{staticClass:"centent",domProps:{"innerHTML":_vm._s(item)}})])}),0):_c('div',{staticStyle:{"color":"#999"}},[_vm._v(_vm._s(_vm.noShowParameterNotice))])]):_vm._e(),(step.name === 'Summary')?_c('div',{staticClass:"h40"},[(_vm.caseInfo.summary)?_c('el-button',{staticStyle:{"width":"100%","text-align":"left"},attrs:{"plain":""}},[_vm._v(_vm._s(_vm.caseInfo.summary))]):_vm._e(),_c('el-switch',{attrs:{"value":_vm.caseInfo?.metaConfig?.email?.notify === 'true' ||
                _vm.caseInfo?.metaConfig?.email?.notify === true,"disabled":"","inactive-color":"#eee","active-color":"#13ce66","inactive-text":"Send email notification when execution failed"}})],1):_vm._e()])])])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }